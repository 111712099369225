import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";

import "./styles/general.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
